<template>
    <b-container fluid>
        <div class="card card-primary card-tabs">
            <div class="p-0 pt-1 card-header">
                <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-one-faq-tab" data-toggle="pill" href="#custom-tabs-one-faq" role="tab" aria-controls="custom-tabs-one-faq" aria-selected="true">FAQs</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-one-groups-tab" data-toggle="pill" href="#custom-tabs-one-groups" role="tab" aria-controls="custom-tabs-one-groups" aria-selected="true">FAQ-Gruppen</a>
                    </li>
                </ul>
            </div>

            <div class="card-body">
                <div class="tab-content" id="custom-tabs-one-tabContent">
                    <div class="tab-pane fade show active" id="custom-tabs-one-faq" role="tabpanel" aria-labelledby="custom-tabs-one-faq-tab">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-tools">
                                    <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('faqs.create')">
                                        <i class="fas fa-plus-circle"></i> Neue FAQ
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Frage</th>
                                            <th>Antwort</th>
                                            <th>Gruppe</th>
                                            <th>Firma</th>
                                            <th style="width: 140px;">Aktion</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(faq,index) in faqs" :key="faq.id">
                                            <td>{{ index +1 }}</td>
                                            <td>{{ faq.question }}</td>
                                            <td>{{ faq.answer }}</td>
                                            <td>{{ faq.group_id }}</td>
                                            <td style="width: 240px;"><span v-for="company in faq.company" :key="company.id">{{ company.name }}<br/></span></td>
                                            <td>
                                                <b-button size="xs" @click="editModal(faq)" class="mr-1" variant="warning" v-if="$auth.check('faqs.edit')"><i class="fas fa-edit"></i></b-button>
                                                <b-button size="xs" @click="deleteFaq(faq.id)" variant="danger" v-if="$auth.check('faqs.destroy')"><i class="fas fa-trash"></i></b-button>
                                            </td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="custom-tabs-one-groups" role="tabpanel" aria-labelledby="custom-tabs-one-groups-tab">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-tools">
                                    <button type="button" class="btn btn-primary" @click="createGroupModal" v-if="$auth.check('faqgroups.create')">
                                        <i class="fas fa-plus-circle"></i> Neue Gruppe
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th style="width: 140px;">Aktion</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(group,index) in allGroups" :key="group.id">
                                            <td>{{ index +1 }}</td>
                                            <td>{{ group.name }}</td>
                                            <td>
                                                <b-button size="xs" @click="editGroupModal(group)" class="mr-1" variant="warning" v-if="$auth.check('faqgroups.edit')"><i class="fas fa-edit"></i></b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="faqModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="title" class="control-label">Frage*</label>
                            <input v-model="form.question" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('question')}">
                            <has-error :form="form" field="question"></has-error>
                        </div>
                        <div class="form-group">
                            <label for="title" class="control-label">Antwort*</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.answer" :class="{'is-invalid': form.errors.has('answer')}" v-if="notEmptyObject(form)"></ckeditor>
                            <has-error :form="form" field="answer"></has-error>
                        </div>
                        <!-- <div class="form-group">
                            <label for="title" class="control-label">Gruppe*</label>
                            <input v-model="form.group_id" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('group_id')}">
                            <has-error :form="form" field="group_id"></has-error>
                        </div> -->
                        <div class="form-group">
                            <label for="title" class="control-label">Gruppe*</label>
                            <select class="float-right form-control form-control-sm" v-model="form.group_id" >
                                <option selected value="">-- Wähle eine Gruppe --</option>
                                <option v-for="group in allGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="title" class="control-label mt-3">Firma*</label>
                            <select class="float-right form-control form-control-sm" multiple v-model="form.companies">
                                <option v-for="company in allCompanies" :key="company.id" :value="company.id">{{ company.name }}</option>
                            </select>
                            <has-error :form="form" field="companies"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <b-modal id="groupModal" v-bind:title="modalTitleGroup" ok-only ok-variant="danger" ok-title="Speichern" size="md" @ok="handleOkGroup($event, formGroup.id)">
            <form ref="form" @submit.stop.prevent="handleSubmitGroup">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="title" class="control-label">Name*</label>
                            <input v-model="formGroup.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': formGroup.errors.has('name')}">
                            <has-error :form="formGroup" field="name"></has-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Icons</label>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Firma</th>
                                <th>Icon</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(company, index) in formGroup.companies" :key="company.id">
                                <td>
                                    {{ allCompanies[index].name }}
                                </td>
                                <td>
                                    <v-select :options="formGroup.companies[index].icons" v-model="formGroup.companies[index].icon_id" :reduce="icon => icon.id" label="name" :class="{'is-invalid': form.errors.has('icon_id')}">
                                        <template #search="{attributes, events}">
                                            <input
                                            class="vs__search"
                                            :required="!form.icon_id"
                                            v-bind="attributes"
                                            v-on="events"
                                            />
                                        </template>
                                    </v-select>
                                    <has-error :form="form" field="icon_id"></has-error>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </b-modal>
    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "settings.faqs",
    title: "FAQs",

    data(){
        return {
            faqs: [],
            editMode: false,
            modalTitle: '',
            editModeGroup: false,
            modalTitleGroup: '',
            
            form: new window.Form({
                id: "",
                question: '', 
                answer: '', 
                group_id: '', 
                companies: [], 
            }),

            formGroup: new window.Form({
                id: "",
                name: '',
                companies: [],
            }),
            allGroups: [], 
            allCompanies: [], 
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
        }
    },

    methods:{
        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                this.editFaq(id);
            }
            else
            {
                this.createFaqs();
            }
        },

        createModal()
        {
            this.editMode = false;
            this.modalTitle = "Neue FAQ anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("faqModal");
        },
        
        editModal(Faq)
        {
            this.editMode = true;
            this.modalTitle = "FAQ editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Faq);
            this.$bvModal.show("faqModal");
        },

        createFaqs() {
            this.form
                .post("/faqs")
                .then(() => {
                    this.$bvModal.hide("faqModal");
                    this.$swal({
                        icon: "success",
                        title: "FAQ wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadFaqs();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editFaq(id){
            this.form
                .put("/faqs/"+ id)
                .then(() => {
                    this.$bvModal.hide("faqModal");
                    this.$swal({
                        icon: "success",
                        title: "FAQ wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadFaqs();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        deleteFaq(id){
            this.$swal({
                title: "Möchtest du die FAQ wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/faqs/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Faq erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadClaims();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        handleOkGroup(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmitGroup(id)
        },

        handleSubmitGroup(id) {
            if(this.editModeGroup == true)
            {
                this.editGroup(id);
            }
            else
            {
                this.createGroups();
            }
        },

        createGroupModal()
        {
            this.editModeGroup = false;
            this.modalTitleGroup = "Neue Gruppe anlegen";
            this.formGroup.clear();
            this.formGroup.reset();
            this.$bvModal.show("groupModal");
            this.allCompanies.forEach(element => {
                this.formGroup.companies.push({
                    id: element.id,
                    icon_id: element.icons[0].id,
                    icons: element.icons
                });
            });
        },
        
        editGroupModal(Group)
        {
            this.editModeGroup = true;
            this.modalTitleGroup = "Gruppe editieren";
            this.formGroup.clear();
            this.formGroup.reset();
            this.formGroup.fill(Group);
            this.$bvModal.show("groupModal");
            this.formGroup.companies = [];
            this.allCompanies.forEach(element => {
                this.formGroup.companies.push();
                this.formGroup.companies.push({
                    id: element.id,
                    icon_id: Group.icons.find(company => company.company_id == element.id).id,
                    icons: element.icons
                });
            });
        },

        createGroups() {
            this.formGroup
                .post("/faqgroups")
                .then(() => {
                    this.$bvModal.hide("groupModal");
                    this.$swal({
                        icon: "success",
                        title: "Gruppe wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadFaqGroups();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editGroup(id){
            this.formGroup
                .put("/faqgroups/"+ id)
                .then(() => {
                    this.$bvModal.hide("groupModal");
                    this.$swal({
                        icon: "success",
                        title: "Gruppe wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadFaqGroups();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadFaqs(){
            this.axios
                .get("/faqs")
                .then((response) => {
                    this.faqs = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadFaqGroups(){
            this.axios
                .get("/faqgroups")
                .then((response) => {
                    this.allGroups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.allCompanies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created(){
        this.loadFaqs();
        this.loadFaqGroups();
        this.loadCompanies();
    }

}
</script>

<style>
body {
        --ck-z-default: 100;
        --ck-z-modal: calc( var(--ck-z-default) + 999 );
    }
</style>